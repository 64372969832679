import { default as IBAN } from "iban";
import { default as BIC } from "bic";
import { DATE_TIME_REGEX } from "@/constants/DateRegex";

export const pdfRequired: any = (value: File) => {
  if (!value) {
    return true;
  }
  return value.type === "application/pdf";
};

export const iban: any = (value: string) => {
  return IBAN.isValid(value);
};

export const ibanEmpty: any = (value: string) => {
  if (!value) return true;
  return IBAN.isValid(value);
};

export const bic: any = (value: string) => {
  return BIC.isValid(value);
};

export const checkDateRegex: any = (value: string) => {
  if (!value) {
    return true;
  }
  return DATE_TIME_REGEX.date.test(value);
};









































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import { required } from "vuelidate/lib/validators";
import { Validations } from "vuelidate-property-decorators";
import { pdfRequired } from "@/validators/validators";
import AbortButton from "@/components/Button/AbortButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import { template } from "lodash";
import { TEMPLATES_MAP } from "@/constants/Validations";
import FileButton from "@/components/Button/FileButton.vue";

@Component({
  components: { FileButton, UploadButton, FscSimpleCard, AbortButton, Validation },
})
export default class UploadDocument extends Vue {
  public name = "UploadDocument";

  public files: Array<File> = [];

  @Prop({ default: () => false, type: Boolean })
  public isLoading!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public clearFiles!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public fileSizeInvalid!: boolean;

  private isInvalidFileSize = false;

  public onUploadFile(): void {
    this.files = Array.from((this.$refs.document as HTMLFormElement).files);
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      files: {
        required,
        $each: {
          pdfRequired,
        },
      },
    };
  }

  protected onSubmit(): void {
    this.$v.files.$touch();

    if (this.$v.$invalid) return;
    this.$emit("select", this.files);
  }

  protected onAbort(): void {
    this.$parent.$emit("on-abort");
    this.$emit("on-abort");
  }

  protected removeFile(index: number): void {
    this.files.splice(index, 1);

    // Remove file from DOM
    const dt = new DataTransfer();
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      dt.items.add(file);
    }

    (this.$refs.document as HTMLFormElement).files = dt.files;
    this.isInvalidFileSize = false;
  }

  @Watch("fileSizeInvalid", { immediate: true })
  onChangeValidFileSize(v: any) {
    this.isInvalidFileSize = v;
  }
  @Watch("clearFiles")
  public onClearFiles(clearFiles: boolean): void {
    if (clearFiles) {
      this.isInvalidFileSize = false;
      this.removeFile(0);
    }
  }

  protected get vl() {
    return this.$v.files.$each?.$iter ? Object.keys(this.$v.files.$each.$iter) : null;
  }

  protected get eachValidationError() {
    return this.vl?.map((x: any) => {
      //@ts-ignore
      return Object.keys(this.$v.files.$each[x].$params).reduce((errors: Array<string>, validator) => {
        //@ts-ignore
        if (!this.$v.files.$each[x][validator]) {
          const compiled = template(TEMPLATES_MAP[validator]);
          //@ts-ignore
          const error: string = compiled(this.$v.files.$each[x].$params[validator]);
          errors.push(error);
        }
        return errors;
      }, [])[0];
    })[0];
  }
}

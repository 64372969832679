<template>
  <b-button variant="outline-secondary" @click="$emit('click')">
    <span class="mr-2">{{ $t("general.select_file") }}</span>
    <font-awesome-icon :icon="['fat', 'paperclip']" />
  </b-button>
</template>

<script>
export default {
  name: "FileButton",
};
</script>
